import React, { useEffect, useState , forwardRef, useRef } from "react";
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import EmployeeTransferList from "../EmployeeTransferList";
import { Paper, Typography } from "@mui/material";
import QuestionSetup from "../QuestionSetup";
import deleteBtn from '../../assets/css/images/delete_btn.png'
import questionData from '../../json/360-questions.json';
import data from '../../json/employees.json'
import uuid from 'react-uuid';
import Select from 'react-select';
import {Table} from "react-bootstrap";
import { PropaneSharp } from "@mui/icons-material";
import { ReviewerList } from "../ReviewerList";
import Modal from 'react-bootstrap/Modal';
import { API } from "../../Controllers/API";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const BackBtn = forwardRef((props, ref) => {
  return <a {...props}
  onClick={props.OnClickThis}
  ref={ref} ></a>
})

const ThreeSixty = (props)=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const token = JSON.parse(sessionStorage.getItem('token'))
  const itemRef = useRef(null);
  const [savedWorksheets, setSavedWorksheets] = useState([])
  const [switchOne, setSwitchOne] = useState(false)
const [worksheetDataArray, setWorksheetDataArray] = useState([])
const [renderWorkSheetData, SetRenderWorkSheetData] = useState([])
const [currentQuestion,setCurrentQuestion] = useState(null)
const [addCurrentQuestion,setAddCurrentQuestion] = useState(null)
const [update,setUpdate] = useState('')
const [customSelectVal,setCustomSelectVal] = useState()
const [customCriteriaQuery,setCustomCriteriaQuery] = useState('')
const [carbonCopyList, setCarbonCopyList] = useState([])
const [ccName, setCcName] = useState('')
const [ccEmail, setCcEmail] = useState('')
const [value, setValue] = useState([]);
const [reviewers, setReviewers] = useState([[{name:"null",email:"null"}]])
const [reviewee, setReviewee] = useState('')
const [show, setShow] = useState(false);
const [jTshow, setJtShow] = useState(true);
const [eIDshow, setEiDShow] = useState(true);
const [departmentShow, setDepartmentShow] = useState(true);
const [tipShow, setTipShow] = useState(true);
const [goalPlanningSection, setGoalPlanningSection] = useState(false)
const [reviewObj, setReviewObj] = useState()
const [jobTitle, setJobTitle] = useState('')
const [employeeId, setEmployeeId] = useState('')
const [department, setDepartment] = useState('')
const [timeInPosition, setTimeInPosition] = useState('')
const [reviewPeriod, setReviewPeriod] = useState('')
const [loading, setLoading] = React.useState(true);
const [nextEvaluationDateShow, setNextEvaluationDateShow] = useState(true);
const [nextEvaluationDate, setNextEvaluationDate] = useState('');
const handleNextEvaluationDate = (e) => setNextEvaluationDate(e);
const [managers, setManagers] = useState([{
  dateOfLastReview: "NA",
email: "null",
id: "null",
name: "null",
reviews: [''],
role: "Manager"
}])
const [employees, setEmployees] = useState([{
  dateOfLastReview: "NA",
email: "null",
id: "null",
name: "null",
reviews: [''],
role: "Employee"
}])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleJobTitle = (e) => setJobTitle(e);
const handleEmployeeId = (e) => setEmployeeId(e);
  const handleDepartment = (e) => setDepartment(e);

  const handleTimeInPosition = (e) => setTimeInPosition(e);

  const handleReviewPeriod = (e) => setReviewPeriod(e);

  
const ShowDemo = ()=>{
  handleShow()


}

const deleteWorksheet = async (a,b)=>{
  console.log(a+" "+b)
     let resp = await axios.post(API+'delete-worksheet',{id:data.id,worksheetId:a,type:b}, (err,response)=>{
      return response
     })
    console.log(resp.data)
     if(resp.data == 'worksheet deleted'){
      setSavedWorksheets(savedWorksheets.filter(i=>{return (i.id != a)}))
     }
  }


const SetupReview = ()=>{

  let reviewerArr = []


  reviewers[0].forEach(i=>{
    reviewerArr.push({
      id:uuid(),
      name:i.name,
      firstName:i.firstName,
              lastName:i.lastName,
      email:i.email,
      status:'pending'
    })
  })
  let date = new Date()
  let parsedDate = date.toLocaleDateString()
  let reviewId = uuid()
  let reviewObject = 
  {
    reviewId: reviewId,
    reviewee: reviewee,
    reviewFrom:{id:token.subaccount ? token.subdata.id : data.id, name:token.subaccount ? token.subdata.firstName + ' ' + token.subdata.lastName : data.name, email:token.subaccount ? token.subdata.email : data.replyToEmail},
    reviewType: '360 Evaluation',
    reviewers: reviewerArr,
    dateCreated: parsedDate,
    nextReviewDate:nextEvaluationDate,
    ccGroup:carbonCopyList,
    link:`http://localhost:3000/?a=${data.id}&b=${reviewee.id}&c=${reviewId}`,
    reviewData: {
      worksheetData:worksheetDataArray,
        goalPlanningSection:goalPlanningSection,
        jobTitle:jobTitle,
        employeeId:employeeId,
        department:department,
        timeInPosition:timeInPosition,
        reviewPeriod:reviewPeriod,
       
        type: '360 Evaluation'
    }
  }
  if(reviewee.reviewee === '' ||  reviewerArr[0].name === 'null' ){
    window.alert('Please make sure you have filled in all needed information. Reviewee, Reviewers and the review period.')

  } else{
    setSwitchOne(true)

  axios.post(API+'add-review',{id:data.id, reviewID:reviewId,obj:reviewObject}).then((response)=>{
      
    return response
  
        }).then((response)=>{
          console.log(response.data[0])
     
          return response

         
         
        }).then(((response)=>{
          setManagers(response.data[0].employees.filter(i=>{return (i.role === "Manager")}))
          setEmployees(response.data[0].employees.filter(i=>{return (i.role === "Employee")}))
         
          
          setUpdate(!update)
          setLoading(false)
        })).then(()=>{
          setSwitchOne(false)
         
          setReviewee({reviewee:''})
          window.reviewerList = '[{"name":"null","email":"null"}]'
          reviewerArr[0].name = 'null'
          itemRef.current.click()})
      }
}

let employeeArr = {
  type:'Employees',
  people:[]
}

let managerArr = {
  type:'Managers',
  people:[]
}
const [totalReveieweeArr, setTotalRevieweeArr] = useState([managerArr,employeeArr])
let noDupe1;
let noDupe2;
const sendReviews = ()=>{
  let totalReviewData = {
    reviewee: reviewee,
    

  }
  console.log(totalReviewData)

}
useEffect(()=>{

  console.log(reviewee)
  sendReviews()
},[reviewee])

useEffect(()=>{
 
  axios.post(API,{email:data.email}).then((response)=>{
      
    return response
  
        }).then((response)=>{
          console.log(response.data[0])
          
          return response

          
         
        }).then(((response)=>{
          setManagers(response.data[0].employees.filter(i=>{return (i.role === "Manager")}))
          setEmployees(response.data[0].employees.filter(i=>{return (i.role === "Employee")}))
          setSavedWorksheets(response.data[0].savedWorksheets[1].worksheets)
          setUpdate(!update)
          setLoading(false)
        }))
},[])


useEffect(()=>{
  data.employees.forEach(i=>{
    if(i.role === 'Manager'){
      grpOpts[0].data.push({
        label:i.name,
        value:i.id
      })
    }
      if(i.role === 'Employee'){
        grpOpts[1].data.push({
          label:i.name,
          value:i.id
        })
    }

    
  })
  options = [
  
    createGroup(grpOpts[0].name, grpOpts[0].data, setValue),
    createGroup(grpOpts[1].name, grpOpts[1].data, setValue)
  ]
  setValue(options)
  setUpdate(!update)
},[])
const grpOpts = [{
  name:'managers',

  data:[
  
]},{
  name:'employees',
  data: [
 
]}

]
const createGroup = (groupName, options, setValue) => {
  return {
    label: (() => {
      return (
        <div
          onClick={() =>
            setValue(value =>
              value.concat(options.filter(grpOpt => !value.includes(grpOpt)))
            )
          }
        >
          {groupName}
        </div>
      );
    })(),
    options: options
  };
};
let options = [
  
  createGroup(grpOpts[0].name, grpOpts[0].data, setValue),
  createGroup(grpOpts[1].name, grpOpts[1].data, setValue)
];
let initData = questionData.map(i=>{

  return ({
    catIndex:i.index,
    cat:i.name
  })
  
}
  )
const [customCategories,setCustomCategories] = useState(initData)
const [custom, setCustom] = useState('none')

const [customCatQuery, setCustomCatQuery] = useState('')
const handleSetCustomQuery = (e) =>{
  setCustomCatQuery(e)
  console.log(customCatQuery)
}

const handleCcName = (e) =>{
  setCcName(e)
  console.log(ccName)
}


const handleCcEmail = (e) =>{
  setCcEmail(e)
  console.log(ccEmail)
}

const handleRevieweeSelect = (e) =>{
  setReviewee(e)
  console.log(reviewee)
}


const handleTextArea = (e) =>{
  setCustomCriteriaQuery(e)
  console.log(customCriteriaQuery)
}

useEffect(()=>{
  SetRenderWorkSheetData(worksheetDataArray)
  if(currentQuestion){
    
  setUpdate(currentQuestion.catIndex)

}
},[currentQuestion])

useEffect(()=>{
  SetRenderWorkSheetData(worksheetDataArray)
},[update])
const addQuestion = (e)=>{
  if(currentQuestion != null){


    let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == currentQuestion.catIndex));
  
    if(objIndex === -1){
    
      worksheetDataArray.push({
        catIndex: currentQuestion.catIndex,
        cat:currentQuestion.cat,
        questions:[{
          id:uuid(),
          question: currentQuestion.question}]
    })
 
    setUpdate(!true)
    } else{
      worksheetDataArray[objIndex].questions.push({
        id:uuid(),
        question: currentQuestion.question
       
    })
    setUpdate(!update)
    }

  }

}


const removeQuestion = (a,b)=>{
let conf = window.confirm("You are deleting this question. Are you sure you would like to do this?")
if(conf){
  
let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == a));
console.log(objIndex)
   let filter = worksheetDataArray[objIndex].questions.filter(i=> {return( i.id !== b)})
   console.log(worksheetDataArray[objIndex].questions.length)
if(worksheetDataArray[objIndex].questions.length < 2){
  worksheetDataArray.splice(objIndex,1)
} else{
  worksheetDataArray[objIndex].questions = filter;

}

   setUpdate(!update)

}  
}

const removeEntireCategory = (a)=>{
  let conf = window.confirm("This will delete all of the questions and this category. You can always re-add them if you would like. Are you sure you would like to do this?")
if(conf){
  let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == a));
  worksheetDataArray.splice(objIndex,1)
  setUpdate(!update)
}
}

const clear = ()=>{
  let conf = window.confirm("This will clear the entire worksheet. Are you sure you would like to do this?")
  if(conf){
    setWorksheetDataArray([])
    SetRenderWorkSheetData([])
    console.log(worksheetDataArray)

  }
}


const useSuggested = ()=>{
  let conf = window.confirm("This will clear all of the current worksheet criteria and add suggested criteria. Are you sure you would like to do this?")
  if(conf){
let arr =[]
    questionData.forEach(i=>{
      arr.push({
        catIndex:i.index,
        cat: i.name,
        questions:i.questions
      })
    })
    setWorksheetDataArray(arr)
    setUpdate(!update)
  }
}


const addCustomQuestion = (e)=>{

  let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == e.catIndex));
  
  if(objIndex === -1){
  
    worksheetDataArray.push({
      catIndex: e.catIndex,
      cat:e.cat,
      questions:[{
        id:uuid(),
        question: e.question}]
  })

  setUpdate(!true)
  } else{
    worksheetDataArray[objIndex].questions.push({
      id:uuid(),
      question: e.question
     
  })
  setUpdate(!update)
  setCustomCriteriaQuery('')
  }

}
    return (
      <div>
      <Form.Group className="mb-3">
      <Form.Group className="mb-3">
        <Form.Label>Person that is being evaluated:</Form.Label>
        <Form.Select  onChange={(e)=>{
          let val = JSON.parse(e.target.value)
      
          handleRevieweeSelect(val)
         
        }}>
        <option value="null">Select a Person to be reviewed...</option>
        <optgroup label="Managers">
       {managers.map(i=>{
          let val = {
            id:i.id,
            firstName:i.firstName,
              lastName:i.lastName,
            name:i.name, 
            email:i.email,
            status:'pending'
          }
        return <option  value={JSON.stringify(val)}>{i.name}</option>
       })}
       </optgroup>


       <optgroup label="Employees">
       {employees.map(i=>{
        let val = {
          id:i.id,
          firstName:i.firstName,
              lastName:i.lastName,
          name:i.name, 
          email:i.email,
          status:'pending'
        }
        return <option value={JSON.stringify(val)}>{i.name}</option>
       })}
       </optgroup>
        </Form.Select>
      </Form.Group>
   </Form.Group>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header><b>REVIEWERS</b></Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mb-3">
      <Form.Label><b>Select Reviewers:</b></Form.Label>
      <p>Click a person’s name to add them as a reviewer. If you would like to remove a person you can click their name in the right column.</p>
       <ReviewerList/>
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><b>SETUP CRITERIA</b></Accordion.Header>
        <Accordion.Body>
        <QuestionSetup 
        QuestionSelectOnChange={(e)=>{
           let val  = JSON.parse(e.target.value);

           console.log(val)
           
           setCurrentQuestion(val)
        }}
        QuestionData={questionData.map(i=>{
          let cat = i.name;

             return(<optgroup label={cat}>
              {i.questions.map(a=>{
                let val = {
                  catIndex:i.index,
                  cat:cat, 
                  question:a.question
                }
              
                return(<option value={JSON.stringify(val)}>{a.question}</option>)
              })}
             </optgroup>)
        })}
        CategoryData={customCategories.map(i=>{
 
          let val = {
            catIndex:i.catIndex,
            cat:i.cat
          }
          console.log(val)
          return(<option value={JSON.stringify(val)}>{i.cat}</option>)
        })}
        WorkSheetData={renderWorkSheetData.map(i=>{
          let cat = i.cat
          return(<div>
            <p><b>{cat}</b><a style={{cursor:'pointer'}} onClick={()=>{removeEntireCategory(i.catIndex)}}> <img src={deleteBtn} width="10px" height="10px"/></a></p>
            {i.questions.map(a=>{
              return (<p>{a.question}<a style={{cursor:'pointer'}} onClick={()=>{removeQuestion(i.catIndex, a.id)}}> <img src={deleteBtn} width="10px" height="10px"/></a></p>)
            })}
          </div>)
        })}
        AddQuestion={()=>{addQuestion(currentQuestion);console.log(currentQuestion)}}
        ClearButton={clear}
        SuggestedButton={useSuggested}
        CustomDisplay={custom}
        CustomCatValue={customCatQuery}
        CustomCriteriaValue={customCriteriaQuery}
        SetCustomCategory={(e)=>{
          console.log(e.target.value)
          setCustomSelectVal(JSON.parse(e.target.value))
        }}
          CustomCat={(e)=>{
            handleSetCustomQuery(e.target.value)
           
          }}
          OnCustomCriteriaChange={(e)=>{
            handleTextArea(e.target.value)
            
            
          }}
          OnAddCustomQuestion={(e)=>{
            
            let obj = {
              catIndex:customSelectVal.catIndex,
              cat:customSelectVal.cat,
              question:customCriteriaQuery
            }

          addCustomQuestion(obj)
          setUpdate(!update)
            
          }}
          AddCustomCategory={()=>{
            console.log(customCatQuery)
            let val = {
              catIndex:uuid(),
              cat:customCatQuery.toUpperCase()
            }
            customCategories.push(val)
            setCustomCatQuery('')
            console.log(customCategories)
          }}
          SaveWorksheet={async()=>{
            let value = window.prompt('Please enter a name for the worksheet.')
            if(value){
            console.log(value)
            let val = {
             id:uuid(),
             title:value,
             type:'360',
            data: worksheetDataArray,
            }
            let resp = await axios.post(API+'add-worksheet',{id:data.id, obj: val},(err,data)=>{
                    return data
            })
            console.log(resp.data)
            if(resp.data === 'worksheet added'){
              window.alert('Worksheet saved.')
              savedWorksheets.push(val)
            } else{
              window.alert('Something went wrong. Worksheet was not saved. Please try again.')

            }
            setUpdate(!update)
          }
           }}
          WorksheetTableItems={savedWorksheets.map(i=>{
           
            return (
          
              <tr>

              <td key={i.id}>{i.title}</td>
              <td style={{textAlign:'center'}}>
              <Button variant="outlined" style={{ color:'#199A71', fontWeight:'bold', marginRight:'10px'}} onClick={()=>{
                 setWorksheetDataArray(i.data)
                 setUpdate(!update)
              }}>Load</Button>
              <Button variant="outlined" style={{borderColor:'tomato', fontWeight:'bold', color:'tomato'}} onClick={()=>{
                 deleteWorksheet(i.id, i.type)
                 setUpdate(!update)
              }}>Delete</Button>
              </td>
            </tr>
            )
          })}
          
         
        />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header onClick={()=>{
          if(reviewers != null){
            setReviewers(JSON.parse("["+window.reviewerList+"]")); 
            console.log(reviewers)
            setUpdate(!update)
          }
         
        }
          }><b>FINALIZE</b></Accordion.Header>
        <Accordion.Body>
      <Form.Group>
        <Form.Label><b>Enter the review period:</b></Form.Label>
     <Form.Control onChange={(e)=>handleReviewPeriod(e.target.value)}  type="text" placeholder="Review Period"/>
      </Form.Group>
       
    {/* <Typography style={{marginTop:'10px'}}><b>GOAL SECTION</b></Typography>
      <Form.Group>
      <Form.Check 
      onChange={()=>{setGoalPlanningSection(!goalPlanningSection); console.log(goalPlanningSection)}}
           label="Add goal planning section."
          />
      </Form.Group> */}

      <Typography style={{marginTop:'10px', marginBottom:'10px'}}><b>Extra Reviewee Information(optional)</b></Typography>
      {/* <Form.Group> */}
      {/* <InputGroup className="mb-3"> */}
      {/* <InputGroup.Checkbox onChange={()=>{setJtShow(!jTshow); console.log(!jTshow);
        if(jTshow === false){
           setJobTitle('')
           console.log(jobTitle)
        }
        }} aria-label="Checkbox for following text input" />
      <Form.Control value={jobTitle} onChange={(e)=>handleJobTitle(e.target.value)}  disabled={jTshow} placeholder="Add Job Title" aria-label="Text input with checkbox" />
    </InputGroup>
          
 <InputGroup className="mb-3">
        <InputGroup.Checkbox onChange={()=>{setEiDShow(!eIDshow); console.log(!eIDshow);
        if(eIDshow === false){
           setEmployeeId('')
           console.log(eIDshow)
        }}}  aria-label="Checkbox for following text input" />
        <Form.Control value={employeeId} onChange={(e)=>handleEmployeeId(e.target.value)} disabled={eIDshow} placeholder="Add Employee ID" aria-label="Text input with checkbox" />
      </InputGroup> */}
      <InputGroup className="mb-3">
        <InputGroup.Checkbox onChange={()=>{setDepartmentShow(!departmentShow); console.log(!departmentShow);
        if(departmentShow === false){
           setDepartment('')
           console.log(departmentShow)}}} aria-label="Checkbox for following text input" />
        <Form.Control value={department} onChange={(e)=>handleDepartment(e.target.value)} disabled={departmentShow} placeholder="Add Department" aria-label="Text input with checkbox" />
     </InputGroup>

    
      <hr></hr>
      <Typography variant="button"><b>Date of next evaluation</b></Typography>
      <Typography variant="subtitle2">The date will be added to the Evaluation Calendar and will also appear on the Dashboard as the date approaches.</Typography>
      <InputGroup className="mb-3">
      <InputGroup.Checkbox onChange={()=>{setNextEvaluationDateShow(!nextEvaluationDateShow); console.log(!nextEvaluationDateShow);
        if(nextEvaluationDateShow === false){
           setNextEvaluationDate('')
           console.log(nextEvaluationDate)
        }
        }} aria-label="Checkbox for following text input" />
      <Form.Control type="date" value={nextEvaluationDate} onChange={(e)=>handleNextEvaluationDate(e.target.value)}  disabled={nextEvaluationDateShow}  aria-label="Text input with checkbox" />
    </InputGroup>
      <hr></hr>
   
         <Typography variant="buttom"><b>ADD CCs</b></Typography>
         <Form.Group>
          <Form.Label>Add any one you would like to copyon the completed evaluation email.</Form.Label>
         <InputGroup className="mb-3">
        <Form.Control
          placeholder="Recipient's Name"
          value={ccName}
          onChange={(e)=>{
            handleCcName(e.target.value)
          }}
          aria-describedby="basic-addon2"
        />
         <Form.Control
          placeholder="Recipient's Email"
          onChange={(e)=>{
            handleCcEmail(e.target.value)
          }}
          value={ccEmail}
          aria-describedby="basic-addon2"
        />
        <Button onClick={()=>{
            let val = {
              id:uuid(),
              name: ccName,
              email: ccEmail
            }
            setCcEmail('')
            setCcName('')
            carbonCopyList.push(val)
            setUpdate(!update)
          }} style={{color:'white', backgroundColor:'#1979E6'}} id="button-addon2">
          Add
        </Button>
      </InputGroup>
      </Form.Group>
      <Form.Group>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
       {carbonCopyList.map(i=>{
        return(
          <tr>
       
          <td>{i.name}</td>
          <td>{i.email}</td>
          <td style={{textAlign:'center'}}><Button
          onClick={()=>{
            let filter = carbonCopyList.filter(a=>{
              return (i.id !== a.id)
            })
            setCarbonCopyList(filter)
            setUpdate(!update)
             console.log(i.id)
          }}
          style={{padding:'2px', color:'red'}}>Delete</Button></td>
        </tr>
        )
       })}
      </tbody>

    
    </Table>
    <hr></hr>
    <Typography><b>LIST OF REVIEWERS</b></Typography>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
       {reviewers[0].map(i=>{
        return(
          <tr>
      
          <td>{i.name}</td>
          <td>{i.email}</td>
        </tr>
        )
       })}
      </tbody>

    
    </Table>
    
      <hr></hr>
      <div>
       <Link to="demo" onClick={()=> localStorage.setItem('refer', JSON.stringify(
        {reviewee:reviewee, 
        worksheetData:worksheetDataArray,
        department:department,
        reviewPeriod:reviewPeriod,
        ccGroup:carbonCopyList,
        type: '360 Evaluation'
        }))} target="_blank"> <Button style={{backgroundColor:'#1A7AE6',  color:"white"}} onClick={ShowDemo}>Demo the Evaluation</Button></Link>

        <Button onClick={()=>{SetupReview();
       // setLoading(true)
       }} style={{float:'right',backgroundColor:'#1A7AE6', color:"white"}}>Send Evaluations <span><Spinner style={{display:switchOne ? '':'none'}}  size="sm"  animation="border" variant="light"  /></span></Button>
 
    <BackBtn ref={itemRef} OnClickThis={props.OnClickSend}/>
      
      </div>

      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
{/* <div style={{zIndex:10000000}}>
    <Modal style={{zIndex:10000000}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      </div> */}
    </div>
    )
}

export default ThreeSixty;